<template>
  <div class="wrapper">
    <div>
      <headerbox></headerbox>
    </div>
    <div>
      <router-view />
    </div>
    <div>
      <footerbox></footerbox>
    </div>
  </div>
</template>
<script>
import headerbox from "../components/headerbox.vue";
import footerbox from "../components/footerbox.vue";
export default {
  components: {
    headerbox,
    footerbox,
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {},
    },
  },
  data() {
    return {};
  },
  mounted() {
    // this.$router.push({
    //   path: "/indexer",
    //   query: { type: "nav", navid: 1 },
    // });
  },
  methods: {},
};
</script>

<style lang="scss">
p{
  margin: 0;
  padding: 0;
}
</style>
