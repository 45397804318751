<template>
  <div class="" :class="navid == 1 && change_ash == 1 ? 'bgh' : ''">
    <!-- 首页底部 -->
    <div
      class="pt-35 pb-45 border-top border-ligit-BFBFBF flex flex-column align-center justify-center"
    >
      <div class="flex" v-if="token">
        <div v-for="(item, index) in alist" :key="index" class="mr-20 mb-20">
          <!-- target="_blank" -->
          <router-link
            :to="{ path: item.path, query: item.query }"
            v-if="item.type == 1"
            >{{ item.title }}</router-link
          >
          <a href="" v-if="item.type == 2">{{ item.title }}</a>
        </div>
      </div>
      <div class="flex" v-else>
        <div v-for="(item, index) in alist2" :key="index" class="mr-20 mb-20">
          <!-- target="_blank" -->
          <router-link
            :to="{ path: item.path, query: item.query }"
            v-if="item.type == 1"
            >{{ item.title }}</router-link
          >
          <a href="" v-if="item.type == 2">{{ item.title }}</a>
        </div>
      </div>
      <div class="mb-20 flex align-center">
        <div class="font-14 text-333333 cursorp mr-20" @click="cutpath(cert_url3)">
          {{ cert_url1 }}
        </div>
        <div class="font-14 text-333333 cursorp" @click="cutpath(cert_url2)">
          {{ cert_url4 }}
        </div>
      </div>
      <div class="mb-35 flex align-center">
        <div class="font-14 text-333333 cursorp mr-20" @click="cutpath(cert_img1)">
          {{ cert_img1_title }}
        </div>
        <div class="font-14 text-333333 cursorp mr-20" @click="cutpath(cert_img2)">
          {{ cert_img2_title }}
        </div>
        <div class="font-14 text-333333 cursorp" @click="cutpath(cert_img3)">
          {{ cert_img3_title }}
        </div>
      </div>
      <!-- <div class="flex mb-35">
        <div class="mr-10">
          <div style="width: 6.25rem; height: 3.125rem">
            <el-image
              style="width: 100%; height: 100%"
              :src="cert_img1"
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div class="mr-10">
          <div style="width: 6.25rem; height: 3.125rem">
            <el-image
              style="width: 100%; height: 100%"
              :src="cert_img2"
              fit="cover"
            ></el-image>
          </div>
        </div>
        <div class="mr-10">
          <div style="width: 6.25rem; height: 3.125rem">
            <el-image
              style="width: 100%; height: 100%"
              :src="cert_img3"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div> -->
      <div class="font-14 text-333333 mb-20">
        {{ copyright }}
      </div>

      <div class="flex">
        <div
          v-for="(item, index) in imgList"
          :key="index"
          class="mr-10"
          @click="cutpath(item.url)"
        >
          <div style="width: 6.25rem; height: 3.125rem">
            <el-image
              style="width: 100%; height: 100%"
              :src="item.img"
              fit="cover"
            ></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../utils/req";
export default {
  data() {
    return {
      imgList: [],
      alist: [
        {
          title: "关于本站",
          path: "/AboutUs",
          type: 1,
          query: { foottype: 1, type: "AboutUs" },
        },
        {
          title: "服务协议",
          path: "/serviceAgreement",
          type: 1,
          query: { foottype: 2, type: "serviceAgreement" },
        },
        {
          title: "隐私政策",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 3,
            type: "richtext",
          },
        },
        {
          title: "版权申明",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 4,
            type: "richtext",
          },
        },
        {
          title: "商务洽谈",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 5,
            type: "richtext",
          },
        },
        {
          title: "客服中心",
          path: "/mykf",
          type: 1,
          query: { type: "mykf" },
        },
        {
          title: "APP下载",
          path: "/inviteFriends",
          type: 1,
          query: { type: "inviteFriends" },
        },
        {
          title: "推广加盟",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 6,
            type: "richtext",
          },
        },
        {
          title: "战略合作",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 7,
            type: "richtext",
          },
        },
        {
          title: "创作联盟",
          path: "/richtext",
          type: 1,
          query: {
            foottype: 8,
            type: "richtext",
          },
        },
        {
          title: "我的账户",
          path: "/myIndex",
          type: 1,
          query: { type: "myIndex" },
        },
      ],
      alist2: [
        {
          title: "关于本站",
          path: "/richtext3",
          type: 1,
          query: { foottype: 1, type: "richtext3" },
        },
        {
          title: "服务协议",
          path: "/richtext3",
          type: 1,
          query: { foottype: 2, type: "richtext3" },
        },
        {
          title: "隐私政策",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 3,
            type: "richtext3",
          },
        },
        {
          title: "版权申明",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 4,
            type: "richtext3",
          },
        },
        {
          title: "商务洽谈",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 5,
            type: "richtext3",
          },
        },
        {
          title: "客服中心",
          path: "/richtext3",
          type: 1,
          query: {foottype: 9, type: "richtext3" },
        },
        {
          title: "APP下载",
          path: "/inviteFriends",
          type: 1,
          query: { type: "inviteFriends" },
        },
        {
          title: "推广加盟",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 6,
            type: "richtext3",
          },
        },
        {
          title: "战略合作",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 7,
            type: "richtext3",
          },
        },
        {
          title: "创作联盟",
          path: "/richtext3",
          type: 1,
          query: {
            foottype: 8,
            type: "richtext3",
          },
        },
        {
          title: "我的账户",
          path: "/myIndex",
          type: 1,
          query: { type: "myIndex" },
        },
      ],
      copyright_text: "",
      copyright: "",
      change_ash: 0,
      navid: 0,
      cert_img1: "",
      cert_img1_title: "",
      cert_img2: "",
      cert_img2_title: "",
      cert_img3: "",
      cert_img3_title: "",
      cert_url1: "",
      cert_url2: "",
      cert_url3: "",
      cert_url4: "",
      token:"",
    };
  },
  mounted() {
    let navid = this.$route.query.navid;
    this.navid = navid;
    let token = localStorage.getItem("token");
    if (token) {
      this.token = token;
    }
    this.getImg();
    this.getchange_ash();
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.getchange_ash();
        let navid = this.$route.query.navid;
        this.navid = navid;
      },
      deep: true,
    },
  },
  methods: {
    getchange_ash() {
      let that = this;
      req
        .post("index/setting", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.change_ash = res.data.change_ash;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    getImg() {
      let that = this;
      req
        .post("index/outerChain", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (res.data && res.data.length) {
            that.imgList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
      req
        .post("index/copyright", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          this.copyright_text = res.data.copyright_text;
          this.copyright = res.data.copyright;
          this.cert_img1 = res.data.cert_img1;
          this.cert_img1_title = res.data.cert_img1_title;
          this.cert_img2 = res.data.cert_img2;
          this.cert_img2_title = res.data.cert_img2_title;
          this.cert_img3 = res.data.cert_img3;
          this.cert_img3_title = res.data.cert_img3_title;
          this.cert_url1 = res.data.cert_url1;
          this.cert_url2 = res.data.cert_url2;
          this.cert_url3 = res.data.cert_url3;
          this.cert_url4 = res.data.cert_url4;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    cutpath(url) {
      console.log(url);
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style>
.bgh {
  filter: grayscale(1);
}
a {
  text-decoration: none;
  color: #333333;
  font-size: 0.875rem;
}
</style>
