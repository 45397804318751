<template>
  <div>
    <!-- 头部-黑背景 -->
    <div
      class="bg-000000 py-10 black"
      :class="navid == 1 && change_ash == 1 ? 'bgh' : ''"
      v-if="
        (type == 'nav' && navid == 1 && flag) ||
        (type == 'filmdetails' && flag) ||
        (type == 'creationdetails' && flag)
      "
    >
      <div class="px-88 flex a-center justify-between">
        <div class="flex a-center mr-30 x-1">
          <div class="mr-40">
            <img src="@/assets/img/logo_w.png" alt="" class="logo" />
          </div>
          <!-- 带更多的导航 -->
          <ul class="flex a-center" v-if="lableList.length > 10">
            <li
              v-for="(item, index) in lableList1"
              :key="index"
              class="flex-shrink"
              @click="cutnav('/indexer', 'nav', item.id, item.name)"
            >
              <div
                class="mr-15 label cursorp"
                :class="type == 'nav' && navid == item.id ? 'font-weight-bolder' : ''"
              >
                {{ item.name }}
              </div>
            </li>

            <li class="mr-15 label flex-shrink">
              <el-dropdown>
                <div class="mr-15 label cursorp font-16">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in lableList2" :key="index">
                    <div @click="cutnav('/indexer', 'nav', item.id, item.name)">
                      {{ item.name }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
          <!-- 导航 -->
          <ul class="flex a-center" v-else>
            <li
              v-for="(item, index) in lableList"
              :key="index"
              class="mr-15 label cursorp"
              @click="cutnav('/indexer', 'nav', item.id, item.name)"
              :class="type == 'nav' && navid == item.id ? 'font-weight-bolder' : ''"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="flex align-center justify-center flex-shrink">
          <div class="flex a-center mr-30 cursorp flex-shrink">
            <el-input
              v-model="value"
              placeholder="请输入关键词"
              @keyup.enter.native="gosearch('/search')"
            >
              <div
                class="flex a-center search justify-center"
                slot="append"
                @click="gosearch('/search')"
              >
                <div class="mr-10">
                  <i class="el-icon-search"></i>
                </div>
                搜索
              </div>
            </el-input>
          </div>
          <div class="flex align-center ml-5 flex-shrink">
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              @click="gocreationList"
              v-if="type == 'creationList'"
            >
              <img src="@/assets/img/creation_act.png" alt="" class="wh30" />
              原创
            </div>
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              @click="gocreationList"
              v-else
            >
              <img src="@/assets/img/creation.png" alt="" class="wh30" />
              原创
            </div>
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              v-if="type == 'news'"
              @click="goNews"
            >
              <img src="@/assets/img/news_act.png" alt="" class="wh30" />
              消息
            </div>
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              v-else
              @click="goNews"
            >
              <img src="@/assets/img/news.png" alt="" class="wh30" />
              消息
            </div>
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              @click="goViewingrecords"
              v-if="type == 'Viewingrecords'"
            >
              <img src="@/assets/img/playrecord_act.png" alt="" class="wh30" />
              观看记录
            </div>
            <div
              class="mr-30 cursorp font-12 text-white flex flex-column align-center justify-center"
              @click="goViewingrecords"
              v-else
            >
              <img src="@/assets/img/playrecord.png" alt="" class="wh30" />
              观看记录
            </div>
            <div class="cursorp" @click="gomy" v-if="info.avatar">
              <img :src="info.avatar" alt="" class="wh40 circle" />
            </div>
            <div class="cursorp" @click="gologin" v-else>
              <img src="@/assets/img/notlogin.png" alt="" class="wh40" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 头部-白背景 -->
    <div
      class="py-10 bg-white white"
      :class="navid == 1 && change_ash == 1 ? 'bgh' : ''"
      v-else
    >
      <div class="px-88 flex a-center justify-between">
        <div class="flex a-center mr-30 x-1">
          <div class="mr-40">
            <img src="@/assets/img/logo_b.png" alt="" class="logo" />
          </div>
          <ul class="flex a-center" v-if="lableList.length > 10">
            <li
              v-for="(item, index) in lableList1"
              :key="index"
              class="flex-shrink"
              @click="cutnav('/indexer', 'nav', item.id, item.name)"
            >
              <div
                class="mr-15 label2 cursorp"
                :class="type == 'nav' && navid == item.id ? 'font-weight-bolder' : ''"
              >
                {{ item.name }}
              </div>
            </li>
            <li class="mr-15 label2 flex-shrink">
              <el-dropdown>
                <div class="mr-15 label2 cursorp font-16">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item, index) in lableList2" :key="index">
                    <div @click="cutnav('/indexer', 'nav', item.id, item.name)">
                      {{ item.name }}
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
          </ul>
          <ul class="flex a-center" v-else>
            <li
              v-for="(item, index) in lableList"
              :key="index"
              class="mr-15 label2 cursorp"
              @click="cutnav('/indexer', 'nav', item.id, item.name)"
              :class="type == 'nav' && navid == item.id ? 'font-weight-bolder' : ''"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="flex align-center justify-center">
          <div class="flex a-center mr-30 flex-shrink">
            <el-input
              v-model="value"
              placeholder="请输入关键词"
              @keyup.enter.native="gosearch('/search')"
            >
              <div
                class="flex a-center search justify-center"
                slot="append"
                @click="gosearch('/search')"
              >
                <div class="mr-10">
                  <i class="el-icon-search"></i>
                </div>
                搜索
              </div>
            </el-input>
          </div>
          <div class="flex align-center ml-5 flex-shrink">
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              @click="gocreationList"
              v-if="type == 'creationList'"
            >
              <img src="@/assets/img/creation_act.png" alt="" class="wh30" />
              原创
            </div>
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              @click="gocreationList"
              v-else
            >
              <img src="@/assets/img/creation.png" alt="" class="wh30" />
              原创
            </div>
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              v-if="type == 'news'"
              @click="goNews"
            >
              <img src="@/assets/img/news_act.png" alt="" class="wh30" />
              消息
            </div>
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              v-else
              @click="goNews"
            >
              <img src="@/assets/img/news.png" alt="" class="wh30" />
              消息
            </div>
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              @click="goViewingrecords"
              v-if="type == 'Viewingrecords'"
            >
              <img src="@/assets/img/playrecord_act.png" alt="" class="wh30" />
              观看记录
            </div>
            <div
              class="mr-30 cursorp font-12 text-666666 flex flex-column align-center justify-center"
              @click="goViewingrecords"
              v-else
            >
              <img src="@/assets/img/playrecord.png" alt="" class="wh30" />
              观看记录
            </div>
            <div
              class="cursorp flex flex-column align-center justify-center"
              @click="gomy"
              v-if="info.avatar"
            >
              <img :src="info.avatar" alt="" class="wh40 circle" />
            </div>
            <div
              class="cursorp flex flex-column align-center justify-center"
              @click="gologin"
              v-else
            >
              <img src="@/assets/img/notlogin.png" alt="" class="wh40" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../utils/req";
export default {
  data() {
    return {
      activeIndex: "1",
      lableList: [],
      lableList1: [],
      lableList2: [],
      value: "",
      type: "",
      navid: "",
      info: "",
      change_ash: 0,
      windowWidth: 0,
      flag: true,
      screenWidth: null,
    };
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        this.getchange_ash();
        let navid = this.$route.query.navid;
        let type = this.$route.query.type;
        if (navid) {
          this.navid = navid;
        } else {
          this.navid = "";
        }
        if (type) {
          this.type = type;
        } else {
          this.type = "";
        }
        let keyword = this.$route.query.keyword;
        if (keyword) {
          this.value = keyword;
        } else {
          this.value = "";
        }
      },
      windowWidth(val) {
        let that = this;
        console.log("实时屏幕宽度：", val, that.windowHeight);
      },
      screenWidth: function (n, o) {
        if (n <= 1200) {
          console.log("屏幕宽度小于1200了");
        } else {
          console.log("屏幕宽度没有小于1200");
        }
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll); // 监听页面滚动
    var that = this;
    window.fullWidth = document.documentElement.clientWidth;
    that.windowWidth = window.fullWidth; //获取屏幕宽度

    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      console.log("高度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  created() {
    this.getchange_ash();
    let navid = this.$route.query.navid;
    let type = this.$route.query.type;
    if (navid) {
      this.navid = navid;
    } else {
      this.navid = "";
    }
    if (type) {
      this.type = type;
    } else {
      this.type = "";
    }
    let keyword = this.$route.query.keyword;
    if (keyword) {
      this.value = keyword;
    }
    this.getlabel();
    let token = localStorage.getItem("token");
    if (token) {
      this.getMy();
    }
  },
  methods: {
    // 滚动重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    // 获取页面滚动距离
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop, "滚动距离");
      if (scrollTop < 100) {
        this.flag = true;
      } else {
        this.flag = false;
      }
      this.getlabel();
      window.fullWidth = document.documentElement.clientWidth;
      this.windowWidth = window.fullWidth; //获取屏幕宽度
    },
    getchange_ash() {
      let that = this;
      req
        .post("index/setting", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.change_ash = res.data.change_ash;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 获取我的信息
    getMy() {
      let that = this;
      req
        .post("center/my", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.info = res.data;
        })
        .catch((err) => {
          console.log(err);
          // that.$message.error(err.msg);
        });
    },
    // 跳转登录
    gologin() {
      this.$router.push({
        path: "/login",
        query: { type: "login"},
      });
    },
    // 跳转我的
    gomy() {
      this.$router.push({
        path: "/myIndex",
        query: {type: "myIndex"},
      });
    },
    // 跳转原创
    gocreationList() {
      this.type = "creationList";
      this.$router.push({
        path: "/creationList",
        query: { type: "creationList" },
      });
    },
    // 跳转消息
    goNews() {
      this.type = "news";
      this.$router.push({
        path: "/news",
        query: { type: "news" },
      });
    },
    // 跳转观赏记录
    goViewingrecords() {
      this.type = "Viewingrecords";
      this.$router.push({
        path: "/Viewingrecords",
        query: { type: "Viewingrecords" },
      });
    },
    // 原创移入移出
    onmouseover() {},
    onmouseout() {},
    // 搜索跳转
    gosearch(path) {
      this.navid = "";
      this.type = "search";
      this.$router.push({
        path: path,
        query: {type: "search", keyword: this.value },
      });
    },
    // 导航跳转
    cutnav(path, type, id, name) {
      this.$router.push({
        path: path,
        query: { type, navid: id, title: name },
      });
    },

    getlabel() {
      let that = this;
      req
        .post("index/nav", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          if (res.data && res.data.length) {
            if (res.data.length > 11) {
              let arr = [];
              let arr2 = [];
              res.data.forEach((item, index) => {
                if (that.windowWidth < 1600) {
                  if (index < 11) {
                    arr.push(JSON.parse(JSON.stringify(item)));
                  } else {
                    arr2.push(JSON.parse(JSON.stringify(item)));
                  }
                } else {
                  if (index < 11) {
                    arr.push(JSON.parse(JSON.stringify(item)));
                  } else {
                    arr2.push(JSON.parse(JSON.stringify(item)));
                  }
                }
              });
              that.lableList1 = arr;
              that.lableList2 = arr2;
            }
            that.lableList = res.data;
            if(!this.navid&&!this.type){
              this.cutnav('/indexer', 'nav', res.data[0].id, res.data[0].name)
            }
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss">
.bgh {
  filter: grayscale(1);
}
.black {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  .el-input__inner {
    -webkit-appearance: none;
    background-color: rgba(255, 255, 255, 0.1);
    // border-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-left-radius: 6.25rem;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-size: inherit;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 9.4375rem;
  }
  .el-input.is-disabled .el-input__inner {
    -webkit-appearance: none;
    background-color: rgba(255, 255, 255, 0.1);
    // border-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-left-radius: 6.25rem;
    border: 0;
    box-sizing: border-box;
    color: #fff;
    display: inline-block;
    font-size: inherit;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 9.4375rem;
  }
  .search {
    width: 6.5625rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #c9372e;
    color: #fff;
    border-radius: 6.25rem;
    margin-left: -1.125rem;
    cursor: pointer;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: #c9372e;
    color: #fff;
    vertical-align: middle;
    display: table-cell;
    border: 0;
    border-radius: 6.25rem;
    padding: 0;
    white-space: nowrap;
  }
}
.white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  .el-input__inner {
    -webkit-appearance: none;
    background-color: #e6e6e6;
    // border-radius: 6.25rem;
    border-top-left-radius: 6.25rem;
    border-bottom-left-radius: 6.25rem;
    border: 0;
    box-sizing: border-box;
    color: #222222;
    display: inline-block;
    font-size: inherit;
    height: 2.5rem;
    line-height: 2.5rem;
    outline: 0;
    padding: 0 0.9375rem;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 9.4375rem;
  }
  .search {
    width: 6.5625rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #c9372e;
    color: #fff;
    border-radius: 6.25rem;
    margin-left: -1.125rem;
    cursor: pointer;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    background-color: #c9372e;
    color: #fff;
    vertical-align: middle;
    display: table-cell;
    border: 0;
    border-radius: 6.25rem;
    padding: 0;
    white-space: nowrap;
  }
}
</style>
<style scoped lang="scss">
.logo {
  width: 11.625rem;
  height: 4.0625rem;
}
.label {
  line-height: 4.0625rem;
  color: #fff;
  font-size: 1rem;
}
.label2 {
  font-size: 1rem;
  line-height: 4.0625rem;
}
.wh30 {
  width: 1.875rem;
  height: 1.875rem;
}
.wh40 {
  width: 2.5rem;
  height: 2.5rem;
}

@media screen and (max-width: 1200px) {
  .content {
    .conter {
      padding: 0 2.875rem;
    }
    .left {
      display: none;
    }
    .cont .right {
      margin-left: 0;
      // margin: 0 auto;
    }
  }
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1020px) {
}
@media screen and (max-width: 992px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 425px) {
}
@media screen and (max-width: 375px) {
}
</style>
